import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className='Footer-container'>
       
       <a href="https://github.com/bchiang7/v4" target="_blank" rel="noreferrer"> Designed Brittnay Chiang,</a> Built from Scratch by  <a href="https://www.linkedin.com/in/adonis-madera/" target="_blank" rel="noreferrer">Adonis Madera
        
       </a>
     
      </div>
    
  );
};

export default Footer;
